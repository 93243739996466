import { Routes, Route } from 'react-router-dom'
import Home from './home'
import 'bootstrap/dist/css/bootstrap.min.css'
import './global.css'

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  )
}

export default App
